/**
 * Returns a dom element , if parent is set , then under that dom
 * Q('body') | Q('body',Q('html')[0])
 *
 * @param {string|selector} str
 * @param {HTMLElement} [parent]
 * @returns {HTMLElement[]}
 */
function Q( str, parent ) {
	var el = document;
	if ( parent != null ) {
		el = parent;
	}
	let res = [];
	if ( typeof el[ Symbol.iterator ] === 'function' && el.length > 0) {
		el = [...el];
		el.forEach(( item ) => {
			if(item.matches(str)){
				res.push(item);
			}
			res = [ ...res, ...item.querySelectorAll(str) ];
		});
	} else {
		res = [ ...el.querySelectorAll(str) ];
}
	return res;
}

const HCache = {
	templates : {},
};

/**
 *
 * @param {string} templateName
 * @param {object} data
 * @param {HTMLElement} target
 * @param {boolean} overrideContent
 * @returns {HTMLElement|DocumentFragment}
 * @constructor
 */
function H( templateName, data, target, overrideContent = false ) {
	if ( !Object.keys(HCache.templates).includes(templateName) ) {
		const source = Q(templateName)[ 0 ].innerHTML.trim();
		HCache.templates[ templateName ] = Handlebars.compile(source);
	}
	const template = HCache.templates[ templateName ];
	const newElements = [...Utility.createElementFromHTML(template(data))];
	if ( typeof target !== 'undefined' ) {
		if ( typeof overrideContent !== 'undefined' ) {
			if ( overrideContent ) {
				target.innerHTML = '';
			}
		}
		newElements.forEach(( item ) => {
			target.append(item);
		});
	}
	return newElements;
}

const Logger = {
	INFO : 'color: cyan',
	WARN : 'color: orange',
	ERROR : 'color: red',
	log( obj, type = Logger.INFO ) {
		if ( typeof obj === "string" ) {
			console.log(`%c${ obj }`, type);
	} else {
			console.log(obj);
	}
	},
};

/**
 * @namespace
 * @type {{
 *     style : {
 *         getProperty(HTMLElement,string|null) : *|CSSStyleDeclaration,
 *         updateGlobalVar(string,*) : void,
 *     },
 *     math : {
 *         lerp(number,number,number) : number,
 *     },
 *     rand : {
 *         rand() : number,
 *         randInt(number,number) : number,
 *         randString(number,string) : string,
 *     },
 *     createElementFromHTML(string) : HTMLElement,
 *     convertSeconds(number|boolean) : {sec: number, min: number, hour: number, day: number},
 *     shuffle(array) : array,
 * }}
 */
const Utility = {
	/**
	 * CSS interactions
	 */
	style : {
		/**
		 * @param element
		 * @param property
		 * @returns {*|undefined|CSSStyleDeclaration}
		 */
		getProperty( element, property = null ) {
			const style = window.getComputedStyle(element);
			if ( property ) {
				const value = style.getPropertyValue(property);

				const units = {
					's' : ( v ) => {
						return parseFloat(v) * 1000;
					},
					'ms' : ( v ) => {
						return parseFloat(v);
					},
					'px' : ( v ) => {
						return parseFloat(v);
					},
					'fallback' : ( v ) => {
						return v;
					},
				};

				const handler = ( val, toCheck ) => {
					for ( let i = 0 ; i < toCheck.length ; i++ ) {
						const key = toCheck[ i ];
						if ( val.includes(key) ) {
							return units[ key ](val.replace(key, ''));
						}
					}
				};

				const resolvers = {
					'animation-duration' : ( val ) => {
						const unitKeys = [ 's', 'ms' ];
						return handler(val, unitKeys);
					},
					default : ( val ) => {
						return handler(val, Object.keys(units));
					},
				};

				const propertyResolverKeys = Object.keys(resolvers);

				if ( propertyResolverKeys.includes(property) ) {
					return resolvers[ property ](value);
				}

				return resolvers[ 'default' ](value);
			} else {
				return style;
			}
		},
		/**
		 * @param property
		 * @param val
		 */
		updateGlobalVar( property, val ) {
			document.documentElement.style.setProperty(property, val);
		},
	},
	/**
	 * Mathematics functions
	 */
	math : {
		/**
		 * @param s
		 * @param e
		 * @param v
		 * @returns {number}
		 */
		lerp( s, e, v ) {
			return ( 1 - v ) * s + v * e;
		},
		degToRad( deg ) {
			return deg * ( Math.PI / 180 );
		},
		radToDeg( rad ) {
			return rad * ( 180 / Math.PI  );
		}
	},
	/**
	 * Random functions
	 */
	rand : {
		/**
		 * @returns {number}
		 */
		rand() {
			// noinspection UnnecessaryLocalVariableJS
			const ret = ( window.crypto.getRandomValues(new Uint32Array(1))[ 0 ] / 4294967295 ); //NOSONAR
			return ret;
		},
		/**
		 * @param {number} min
		 * @param {number} max
		 * @returns {number}
		 */
		randInt( min, max ) {
			// noinspection UnnecessaryLocalVariableJS
			const ret = Math.floor(Utility.rand.rand() * ( max - min )) + min; //NOSONAR
			return ret;
		},
		/**
		 * @param length
		 * @param characters
		 * @returns {string}
		 */
		randString( length = 16, characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ' ) {
			/**
			 * @type {string}
			 */
			let randString = '';
			let charListLength = characters.length;
			for ( let i = 0 ; i < length ; i++ ) {
				randString += characters[ Utility.rand.randInt(0, charListLength) ];
			}
			return randString;
		},
	},
	/**
	 * @param htmlString
	 * @returns {ChildNode}
	 */
	createElementFromHTML( htmlString ) {
		var element = document.createElement('div');
		element.innerHTML = htmlString.trim();
		return element.children;
	},
	/**
	 * @param sec
	 * @param pad
	 * @returns {{sec: number, min: number, hour: number, day: number}}
	 */
	convertSeconds( sec, pad = false ) {
		const min = 60;
		const hour = 3600;
		const day = 86400;

		let retObj = {
			day : 0,
			hour : 0,
			min : 0,
			sec : 0,
		};

		retObj.day = Math.floor(sec / day);
		sec -= retObj.day * day;
		retObj.hour = Math.floor(sec / hour);
		sec -= retObj.hour * hour;
		retObj.min = Math.floor(sec / min);
		sec -= retObj.min * min;
		retObj.sec = sec;

		if ( pad ) {
			Object.entries(retObj).forEach(( [ key, item ] ) => {
				retObj[ key ] = `${ item }`.padStart(2, '0');
			});
		}

		return retObj;
	},
	/**
	 * @param array
	 * @returns {array}
	 */
	shuffle( array ) {
		var currentIndex = array.length, temporaryValue, randomIndex;

		// While there remain elements to shuffle...
		while ( 0 !== currentIndex ) {

			// Pick a remaining element...
			randomIndex = Math.floor(rand() * currentIndex);
			currentIndex -= 1;

			// And swap it with the current element.
			temporaryValue = array[ currentIndex ];
			array[ currentIndex ] = array[ randomIndex ];
			array[ randomIndex ] = temporaryValue;
		}

		return array;
	}
};

/**
 * @type {{validateEmail(*): boolean}}
 */
const Validators = {
	/**
	 * @param email
	 * @returns {boolean}
	 */
	validateEmail( email ) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	},
};

/**
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
Number.prototype.clamp = function( min, max ) {
	return Math.min(Math.max(this, min), max);
};

/**
 * @param {number} oldLow
 * @param {number} oldHigh
 * @param {number} newLow
 * @param {number} newHigh
 * @returns {number}
 */
Number.prototype.mapRange = function( oldLow, oldHigh, newLow, newHigh ) {
	return newLow + ( newHigh - newLow ) * ( this - oldLow ) / ( oldHigh - oldLow );
};

/**
 * @returns {number[]}
 */
Number.prototype.toBinary = function() {
	var tempArray = ( this >>> 0 ).toString(2).split('');
	tempArray.forEach(function( item, index ) {
		tempArray[ index ] = parseInt(item);
	});
	return tempArray;
};

/**
 * Returns a random element from array
 * @returns {*}
 */
Array.prototype.getRandom = function() {
	return this[ Utility.rand.randInt(0, this.length) ];
};

/**
 * @returns {Array}
 */
Array.prototype.shuffle = function() {
	var currentIndex = this.length, temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while ( 0 !== currentIndex ) {

		// Pick a remaining element...
		randomIndex = Math.floor(Utility.rand.rand() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = this[ currentIndex ];
		this[ currentIndex ] = this[ randomIndex ];
		this[ randomIndex ] = temporaryValue;
	}

	return this;
};